import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../../services/api';
import Header from 'pages/Header';
import Footer from 'pages/Footer';
import CardAllPosts from '../../Home/Card/CardAllPosts/index';

const AllPosts = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(3);
  const listedPostIdsRef = useRef([]);

  const fetchPosts = useCallback(async () => {
    console.log("Fetching posts...");
    try {
      const response = await api.get(`/posts`);

      // Filtrar novos posts que não estão na lista atual de allPosts
      const newPosts = response.data.filter(post => !listedPostIdsRef.current.includes(post.id));

      // Atualizar o estado apenas com os novos posts
      setAllPosts(prevPosts => [...prevPosts, ...newPosts]);

      // Adicionar os IDs dos novos posts à lista de IDs listados
      newPosts.forEach(post => {
        listedPostIdsRef.current.push(post.id);
      });
    } catch (error) {
      console.error('Erro ao buscar posts:', error);
    }
  }, []);

  useEffect(() => {
    console.log("Component mounted, fetching posts...");
    fetchPosts();
  }, []);

  const loadMorePosts = () => {
    console.log("Loading more posts...");
    setVisiblePosts(prev => prev + 3);
  };

  return (
    <>
      <Header />

      <section className="container-svc">
        <div className="row flex-center flex-wrap">
          {allPosts.slice(0, visiblePosts).map(post => (
            <CardAllPosts key={post.id} content={post} />
          ))}
        </div>
      </section>

      {allPosts.length > visiblePosts && (
        <div className="text-center flex-center-column mt-1 mb-6">
          <button className="btn btn-click" onClick={loadMorePosts}>
            Ver mais posts
          </button>
        </div>
      )}

      <Footer />
    </>
  );
};

export default AllPosts;