import React from 'react';
import { createRoot } from 'react-dom/client';
import './css/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container); // Cria uma raiz do React.

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.onload = function() {
  // Move o scroll da página para o topo
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Faz com que a rolagem seja suave
  });
};
