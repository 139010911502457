
// Header e Footer
import Header from "pages/Header";
import Footer from "pages/Footer";
import ProductsMenu from "./ProductsMenu";


const Products = () => {
    return (
        <>
        
            <Header />

            <ProductsMenu />

            <Footer />

        </>
    );
}

export default Products;