
// Header e Footer
import Header from "pages/Header";
import Footer from "pages/Footer";

import iconAbout from '../../svg/icon-about.svg';
import bannerAbout from '../../img/img-about-banner.png';
import person from '../../img/person.svg';

const About = () => {
    return (
        <>
                <Header />

                <div className="container-about">
                    <img src={iconAbout} className="about-img ml-2" alt="" /><h3 className="ml-2">Sobre nós</h3>    
                </div>

                <section className="container">
                    <div className="banner-about">
                        <div className="img-banner-about">
                            <img className="img-banner-about-className" src={bannerAbout} alt=""/>
                        </div>
                    </div>
                </section>

                <section className="container-about-2">
                    <div className="banner-about-2">
                        <div className="grid-3 about-grid flex-center">
                            <img src={person} className="about-person" alt="" />
                            <h5 className="about-name">Fabiano Freire</h5>
                            <h6 className="about-desc">CEO Coffee Master</h6>
                        </div>
                
                        <div className="grid-7 about-story about-grid flex-center">
                            <h6>Conheça Fabiano Freire, CEO da Coffee Master. Com uma verdadeira paixão pelo café e uma admiração por esportes e carros antigos, ele encontra na Coffe Master a combinação perfeita de suas paixões. Aqui, ele vê a oportunidade de elevar o padrão da indústria do café, compartilhando experiências excepcionais com outros entusiastas. Juntos, eles estão prontos para redefinir o cenário do café</h6>
                            <br></br>
                            <h6>Na Coffe Master, somos mais do que apenas uma empresa de máquinas de café - somos a sua parceira de confiança para todas as suas necessidades de café. Como referência no mercado, oferecemos locação, vendas e manutenção de máquinas de café, combinando tradição e sofisticação com as mais recentes inovações tecnológicas. Representamos as melhores marcas do setor e garantimos que sua experiência com café seja sempre excepcional. Confie na Coffe Master para transformar sua paixão por café em uma experiência verdadeiramente memorável.</h6>
                        </div>
                    </div>
                </section>

            <Footer />

        </>
    );
}

export default About;