import { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import IconFacebook from '../../svg/icon-facebook.svg';
import IconInstagram from '../../svg/icon-instagram.svg';
import IconTwitter from '../../svg/icon-twitter.svg';

const Contact = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');

    const handleNomeChange = (e) => {
        setNome(e.target.value);
    };

    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };

    const handleMensagemChange = (e) => {
      // Remover caracteres inválidos
      const mensagemSemCaracteresInvalidos = e.target.value.replace(/[#$%¨&]/g, '');
      // Limitar o número de caracteres
      const mensagemLimitada = mensagemSemCaracteresInvalidos.slice(0, 500);
      setMensagem(mensagemLimitada);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          // Enviar e-mail para o contato@coffeemaster.com.br
          const response = await fetch('http://localhost:3001/send-email', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ nome, emailCliente: email, mensagem }),
          });
  
          const data = await response.json();
  
          if (response.ok) {
              alert(data.message);
              // Limpar campos após o envio
              setNome('');
              setEmail('');
              setMensagem('');
          } else {
              alert(data.error);
          }
      } catch (error) {
          console.error('Erro ao enviar e-mail:', error);
          alert('Falha ao enviar e-mail. Por favor, tente novamente mais tarde.');
      }
  };

    return (
        <>
            <Header />
            <section className="container-contact">
                <div className="row">
                    <div className="grid-6">
                        <h3>Entre em contato</h3>
                        <p>Informe na mensagem com o maior detalhamento possível o serviço sobre o qual deseja falar.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input className="mb-1 mt-1" type="text" name="name" value={nome} onChange={handleNomeChange} placeholder="Nome" required />
                            </div>
                            <div className="form-group">
                                <input className="mb-1 mt-1" type="email" name="email" value={email} onChange={handleEmailChange} placeholder="E-mail" required />
                            </div>
                            <div className="form-group">
                                <textarea className="mb-1 mt-1" name="content" value={mensagem} onChange={handleMensagemChange} rows="8" placeholder="Mensagem" maxLength={500} required />
                            </div>
                            <button type="submit" className="btn mt-2">Enviar</button>
                        </form>
                    </div>
                    <div className="grid-1 disappear"></div>
                    <div className="grid-5">
                        <h5 className="mt-4">Outros</h5>
                        <h6 className="color-primary mt-4">Info.</h6>
                        <p>Equipamentos para cafés especiais: máquinas de café espresso profissionais WEGA, automática (Jura) e equipamentos Bunn. Contato: Fabiano Freire</p>
                        <h6 className="color-primary mt-4">E-mail</h6>
                        <p>contato@coffeemaster.com.br</p>
                        <h6 className="color-primary mt-4">Redes sociais</h6>
                        <div className="mt-2">
                            <a href="https://www.instagram.com/coffeemaster_minas_gerais"><img src={IconInstagram} className="icon-s" alt="" /></a>
                            <img src={IconFacebook} className="icon-s ml-2" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Contact;
